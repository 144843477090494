import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom'
import axios from 'axios'
import Header from './Header'
import Leftmenu from './Leftmenu'
import Home from './Home'
import AddDoctors from './AddDoctors'
import Editdoctor from './Editdoctor'
import Viewprofile from './Viewprofile'
import Doctorvideolist from './Doctorvideolist'
import './App.css'
import Dashboard from './Dashboard'
import Admindashboard from './Admindashboard'
import Videoview from './Videoview';

import PublicRoute from './Utils/PublicRoute'
import Login from './Login'
import Doctorlist from './Doctorlist'
import Videolist from './Videolist'
import PrivateRoute from './Utils/PrivateRoute'
import { getToken, getUser } from './Utils/Common'
import './css/vendors.min.css'
import './css/bootstrap.min.css'
import './css/bootstrap-extended.min.css'
import './css/components.min.css'
import './css/vertical-menu.min.css'
import './css/login-register.min.css'
import './css/line-awesome.min.css'
import './css/font-awesome.css'


function App () {
  
  //    const sescheck = getUser()
  //   //alert(sescheck.userId)
  //   var a= "";
  //   // alert(sescheck)

  //   if (sescheck) {
  //     return (
  //       <BrowserRouter>
  //          <Header />
  //         <Leftmenu />
  //          <Route path='/' component={Dashboard} />

  //          <Switch>
  //           {/* <Route path='/' component={Dashboard} /> */}
  //           {/* <Route path='/Login' component={Login} /> */}
  //           <PrivateRoute path='/Leftmenu' component={Leftmenu} />

  //           <PrivateRoute path='/AddDoctors' component={AddDoctors} />
  //           <PrivateRoute path='/Doctorlist' component={Doctorlist} />
  //           <PrivateRoute path='/Videolist' component={Videolist} />
  //           <Route path='/Dashboard' component={Dashboard} />
  //           {/* <Route path='/' component={Dashboard} />  */}
  //         </Switch>
  //      </BrowserRouter>
  //     )
  //     }
  

  // else{

  return (
    
    <div className='App'>
      <BrowserRouter>
        {/* <Login /> */}
        
        { getToken() ? (
          <>
            <Header />
            <Leftmenu />
            
            <Switch>
              <Route path='/Leftmenu' component={Leftmenu} />
              <Route path='/Login' component={Login} />
              <Route path='/AddDoctors' component={AddDoctors} />
               <Route path='/Doctorlist' component={Doctorlist} />
               <Route path='/Videolist' component={Videolist} /> 
               <Route path='/Dashboard' component={Dashboard} /> 
               <Route path='/Editdoctor/:id' component={Editdoctor} /> 
               <Route path='/Viewprofile/:id' component={Viewprofile} /> 
               <Route path='/Doctorvideolist/:id' component={Doctorvideolist} /> 
               <Route path="/Videoview/:id" component={Videoview} />
              <Route path='/' component={Dashboard} />
            </Switch>
          </>
        ) : (
          <Login />
        )}
      </BrowserRouter>
    </div>
  )
}
// }

export default App
