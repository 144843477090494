import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { getUser, removeUserSession } from './Utils/Common'

function Header () {
  let history = useHistory()
  var a = ''
  const sescheck = getUser()
  // var a = sescheck.userId;
  if (sescheck) a = sescheck.userId

  const handleLogout = () => { 

    removeUserSession()
    history.push('./../')
    window.location.reload(false);
    
    }

    return (
     <nav className='header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow'>
      <div className='navbar-wrapper'>
        <div className='navbar-header'>
          <ul className='nav navbar-nav flex-row'>
            <li className='nav-item mobile-menu d-md-none mr-auto'>
              <a
                className='nav-link nav-menu-main menu-toggle hidden-xs'
                href='#'
              >
                <i className='ft-menu font-large-1'></i>
              </a>
            </li>
            <li className='nav-item'>
              <Link to='/Dashboard' className='navbar-brand'>
             
             <img src={window.$mainpath + 'images/logo/logo.png'} alt="logo" className='brand-logo'/>
                                  
               
              </Link>
            </li>

            <li className='nav-item d-md-none'>
              <a
                className='nav-link open-navbar-container'
                data-toggle='collapse'
                data-target='#navbar-mobile'
              >
                <i className='la la-ellipsis-v'></i>
              </a>
            </li>
          </ul>
        </div>
        <div className='navbar-container content'>
          <div className='collapse navbar-collapse' id='navbar-mobile'>
            <ul className='nav navbar-nav mr-auto float-left'></ul>
            <ul className='nav navbar-nav float-right'>
              <li className='dropdown dropdown-user nav-item'>
                <a
                  className='dropdown-toggle nav-link dropdown-user-link'
                  href='#'
                  data-toggle='dropdown'
                >
                  <span className='mr-1 user-name text-bold-700'>
                    Administrator
                  </span>
                  <span className='avatar avatar-online'>
                  <img src={window.$mainpath + 'images/portrait/small/avatar-s-20.jpg'} alt=""/>
                  
                    <i></i>
                  </span>
                </a>
                <div className='dropdown-menu dropdown-menu-right'>
                  <div className='dropdown-divider'></div>
                  {/* <a className='dropdown-item' href='login-with-bg-image.html'>
                    <i className='ft-power'></i> Logout
                  </a> */}

                  <NavLink  onClick={handleLogout} to='' className='dropdown-item'>
                    <i class='fa fa-sign-out'></i>
                    <span>Logout</span>
                  </NavLink>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
