import React from 'react'
import { BrowserRouter, Switch, Route, Link, NavLink } from 'react-router-dom'
import Header from './Header'
import { useHistory } from 'react-router-dom'

import Leftmenu from './Leftmenu'

import { getUser, removeUserSession } from './Utils/Common'

function Home () {
  let history = useHistory()
  var a = ''
  const sescheck = getUser()
  // var a = sescheck.userId;
  if (sescheck) a = sescheck.userId

  const handleLogout = () => {
    removeUserSession()
    history.push('./Home')
  }

  if (sescheck) {
    return (
      <div>
        <Header />
        <Leftmenu />
        {/* <NavLink onClick={handleLogout} to=''>
          <i class='fa fa-sign-out'></i>
          <span>Logout</span>
        </NavLink> */}

        {/* <div class="nav-item"><Link exact to="/Login"><span>Login</span></Link></div> */}
      </div>
    )
  }

  return (
    <div>
      {/* <div class='nav-item'>
        <Link exact to='/Login'>
          <span>Login</span>
        </Link>
      </div> */}

<nav className='header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow'>
      <div className='navbar-wrapper'>
        <div className='navbar-header'>
          <ul className='nav navbar-nav flex-row'>
            <li className='nav-item mobile-menu d-md-none mr-auto'>
              <a
                className='nav-link nav-menu-main menu-toggle hidden-xs'
                href='#'
              >
                <i className='ft-menu font-large-1'></i>
              </a>
            </li>
            <li className='nav-item'>
              <Link to='/' className='navbar-brand'>
                <img
                  className='brand-logo'
                  alt='admin logo'
                  src='images/logo/logo.png'
                />
              </Link>
            </li>

            <li className='nav-item d-md-none'>
              <a
                className='nav-link open-navbar-container'
                data-toggle='collapse'
                data-target='#navbar-mobile'
              >
                <i className='la la-ellipsis-v'></i>
              </a>
            </li>
          </ul>
        </div>
        <div className='navbar-container content'>
          <div className='collapse navbar-collapse' id='navbar-mobile'>
            <ul className='nav navbar-nav mr-auto float-left'></ul>
            <ul className='nav navbar-nav float-right'>
              <li className='dropdown dropdown-user nav-item'>
                <a
                  className='dropdown-toggle nav-link dropdown-user-link'
                  href='#'
                  data-toggle='dropdown'
                >
                  {/* <span className='mr-1 user-name text-bold-700'>
                    Administrator
                  </span> */}
                  <span className='avatar avatar-online'>
                    <img
                      src='images/portrait/small/avatar-s-20.jpg'
                      alt='avatar'
                    />
                    <i></i>
                  </span>
                </a>
                <div className='dropdown-menu dropdown-menu-right'>
                  <div className='dropdown-divider'></div>
                  {/* <a className='dropdown-item' href='login-with-bg-image.html'>
                    <i className='ft-power'></i> Logout
                  </a> */}

                  {/* <NavLink to=''>
                    <i class='fa fa-sign-out'></i>
                    <span>Login</span>
                  </NavLink> */}

              <Link exact to='/Login'>
                    <span>Login</span>
              </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    </div>
  )
}

export default Home
