import React, { useEffect, useState, Fragment } from 'react'
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom'

import Viewprofile from './Viewprofile'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'

import Leftmenu from './Leftmenu'

import { getUser, removeUserSession } from './Utils/Common'
function Doctorlist() {

    
    //alert(sescheck.userId)
    const sescheck = getUser()
    var a = sescheck.userId;
      let history = useHistory();

     const [users, setUsers] = useState([]);
     
     useEffect(() => {
         getUsers();
     }, []);

     async function getUsers() {
         const response = await fetch(window.$urlpath + 'users/doclist');
         const users = await response.json();
         setUsers(users);
         console.log(users)
     }

    //  if (sescheck) {
    //     return (
    //       <div>
           
    //         <Leftmenu />
    //         {/* <NavLink onClick={handleLogout} to=''>
    //           <i class='fa fa-sign-out'></i>
    //           <span>Logout</span>
    //         </NavLink> */}
    
    //         {/* <div class="nav-item"><Link exact to="/Login"><span>Login</span></Link></div> */}
    //       </div>
    //     )
    //   }
    return (
        <div className="app-content content">
           <div className="content-wrapper">
            <div className="content-header row">
            <div className="content-header-left col-md-6 col-12 mb-2">
              <h3 className="content-header-title">Doctors List</h3>
             
            </div>
           
          </div>
          <div className="content-body">
          <div className="row">
    <div id="recent-transactions" className="col-12">
        <div className="card">
                
            <div className="card-content">                
                <div className="table-responsive">
                    <table id="recent-orders" className="table table-hover table-xl mb-0">
                        <thead>
                            <tr>
                             <th className="border-top-0">Name des Arztes</th>
                                <th className="border-top-0">Email</th>                                
                                <th className="border-top-0">Telefonnummer</th>
                                <th className="border-top-0">Datum</th>
                                <th className="border-top-0">Aktion</th>
                              
                            </tr>
                        </thead>
                        <tbody>


                          {
                             
                                users.map(user => (  <tr>
                            <td className="text-truncate">
                                    <span className="avatar avatar-xs" style={{marginRight: "10px"}}>
                                        <img className="box-shadow-2" src={window.$urlimage+ user.user_image} alt="avatar"/>
                                    </span>
                                    <span>{user.user_fname}</span>
                                </td>
                                <td className="text-truncate">{user.user_email}</td>
                                <td className="text-truncate">{user.user_phone}</td>
                               
                                <td className="text-truncate p-1">
                                {user.timestamp}
                                </td>
                                <td>
                                
                                   
                                    {/* <span className="badge badge-info mr-1"  style={{width: '30px', height:'30px', lineHeight:'24px'}}><i className="la la-eye"></i></span> */}
                        
                                    <Link exact style={{width: '30px', height:'30px', lineHeight:'24px'}} to={"/Doctorvideolist/"+user.sno} alt='Alle Videos anzeigen' title='Alle Videos anzeigen' class="badge badge-success mr-1"><i className="la la-video"></i></Link>
                                    <Link exact style={{width: '30px', height:'30px', lineHeight:'24px'}} to={"/Editdoctor/"+user.sno} alt='Arztprofil bearbeiten' title='Arztprofil bearbeiten' class="badge badge-danger"><i className="la la-edit"></i></Link>
                                </td>
                              
                               
                            </tr>
                             ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
  
          </div>
        </div>
      </div>
    )
}

export default Doctorlist
