import React, { useEffect, useState, Fragment } from 'react'
import { useHistory } from "react-router-dom";
import './Videolist.css'
import Modal from 'react-modal';
import { Button} from 'react-bootstrap';
import { getUser } from './Utils/Common';
import { NavLink } from 'react-router-dom';
import VideoThumbnail from 'react-video-thumbnail';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 



function Videolist() {
   const sescheck = getUser()
   //alert(sescheck.userId)
   var a = sescheck.userId;
  let history = useHistory();
  const [users, setUsers] = useState([]);

  const [eid, setied] = useState();
  const deletedoctorvideo=(e)=>{

   //   alert("Do you want to delete ")

     confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
           onClick: () => 

          fetch(window.$urlpath + 'users/deletedoctorvideo?vid='+e )
  
          .then((result) => result.json())
          .then((items) => {
            console.log('api',items); 
        
           console.log("I am in deletdoctorconsole")
            
           // window.location.reload(false);
  
    
       })   

        },
        {
          label: 'No',
          onClick: () => alert('Click No')
        }
      ]
     });

   }
  
  useEffect(() => {
      getUsers();
  }, []);

  async function getUsers() {
      const response = await fetch(window.$urlpath + 'users/videolistdata');  
      const users = await response.json();
      setUsers(users);
      console.log(users)
  }









    return (
        <div className="app-content content">
          <div className="content-wrapper">
           <div className="content-header row">
              <div className="content-header-left col-md-6 col-12 mb-2">
                 <h3 className="content-header-title">Video List</h3>
              </div>
               
              </div>
         
                         




                    <div className="content-body">
                    <section id="video-list">
                     <div className="row">
   
   
                     {     
                        users.map(user => (    <div className="col-lg-4 col-md-6">

                        <div className="card">
                        <span className="delete-icon" onClick={()=>deletedoctorvideo(user.sno)}> 
                              <i className="fa fa-trash-o" aria-hidden="true"></i>
                              <span className="watch-text">Video löschen</span>
                              </span>


                              <span className="publish-icon"> 
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              <span className="watch-text">Veröffentlichte</span>
                              </span>



                              <span className="unpublish-icon"> 
                              <i className="fa fa-eye-slash" aria-hidden="true"></i>
                              <span className="watch-text">Unveröffentlicht</span>
                              </span>
                        <NavLink title={user.vtitle} className="blog-img" exact to={"Videoview/" + user.sno} >
                           <VideoThumbnail
                                                        videoUrl={window.$urlvideo + 'docvideo/' + user.vurl}
                                                        thumbnailHandler={(thumbnail) => (thumbnail)}
                                                        width={320}
                                                        height={180}
                                                    />
                               <span class="video-icon video-popup-control" data-id="560"></span>


                              

                         </NavLink>
           
                      <div className="card-body">
                        <a href="#">
                            <h6 className="card-title font-medium-2">
                             {user.vtitle}</h6>
                         </a>
                         <div className="post-meta-wrap">
                             <div className="author vcard"><i className="fa fa-user-circle" aria-hidden="true"></i>{user.vmade}</div>
                             <div className="date"><i className="fa fa-calendar" aria-hidden="true"></i>{user.vdate}</div>
                             <div className="view-count"><i className="fa fa-eye" aria-hidden="true"></i><span>{user.vview}</span></div>
                             <div className="like-count"><i className="fa fa-heart" aria-hidden="true"></i><span>{user.vlike}</span></div>
                          </div>
                          </div>
                       </div>

                 {/* 
                       <div className="card">
                          <a href="#">
                             <img src="https://pixinvent.com/modern-admin-clean-bootstrap-4-dashboard-html-template/app-assets/images/gallery/2.jpg" alt="" className=" card-img-top img-fluid"/>
                               <span class="video-icon video-popup-control" data-id="560"></span>
                          </a>
           
                      <div className="card-body">
                        <a href="#">
                            <h6 className="card-title font-medium-2">
                              Magical mystery tour: a road trip through Mexico
                            </h6>
                         </a>
                          <p className="float-left font-small-3 text-muted">
                             <i class="la la-user font-small-3" aria-hidden="true"></i>Nicolas

                            </p>
                             <p className="float-left font-small-3 text-muted ml-10">Oct 20, 1:00pm</p>
                          </div>
                       </div> */}

                      
                       </div>

                             ))}
                       </div>
 
                   </section>

                </div>
         </div>
      </div>
    )
   }


  

export default Videolist
