import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
window.$mainpath = 'https://jabit.in/wp-admin/'
window.$urlpath2 = 'http://localhost:4000/'

window.$urlpath = 'https://jabit.in/docstubeapi/' //global variable

window.$urlimage = 'https://jabit.in/docprofile/'
window.$docthumb = 'https://jabit.in/docthumb/'
window.$urlvideo = 'https://jabit.in/'
window.$path = 'https://jabit.in/'
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
