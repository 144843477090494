import React from 'react'
import { useState, useEffect } from "react";
import './Videoviewlist.css'
import { Link, Route, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import VideoThumbnail from 'react-video-thumbnail2';

//function Videoviewlist(props) {



const Videoviewlist = (props) => {
    //  alert(props);
    // console.log(props)
    const searchid = props.results
    const [users, setUsers] = useState([]);


    let history = useHistory();
    useEffect(() => {
        getUsers();
    }, []);


   function sayHello(name) {
        alert(`hello, ${name}`);
        history.push('../Videoview/' + name);
        // ../videoview/
    }





    async function getUsers() {
        const response = await fetch(window.$urlpath + 'users/uservideodata?vid=' + searchid);
        const users = await response.json();
        setUsers(users);

    }

    return (
        <div className="hs_rs_videoview_list">
<h2 className="widget-title h5 extra-bold"><span className="title-wrap"><span className="is-arrow-ct">Das könnte Sie auch interessieren:</span></span></h2>
            {
                users.map(user => (<div key={user.sno} className="hs_rs_videoview_list_wrapper">

                    <div className="hs_rs_videoview_list_img_wrapper">
                        {/* <NavLink exact to={"../videoview/"+ user.sno}  title={user.vtitle}  >
                            <img src={window.$urlvideo + 'docthumb/' + user.vimage} alt={user.vtitle} />
                             <span class="video-icon video-popup-control"></span>
                        </NavLink>  */}

                        {/* <a href={"../Videoview/" + user.sno} title={user.vtitle}  >
                        <VideoThumbnail
                         videoUrl={window.$urlvideo + 'docvideo/' + user.vimage}
                         thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                          /> 
                            <span class="video-icon video-popup-control"></span>
                        </a> */}

<a href={"../Videoview/" + user.sno} title={user.vtitle}  >
                        <VideoThumbnail
                         videoUrl={window.$urlvideo + 'docvideo/' + user.vimage}
                         thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                         width={135}
                            height={76}
                          /> 
                            <span class="video-icon video-popup-control"></span>
                            </a>

                    </div>
                    <div className="hs_rs_videoview_list_single_wrapper">
                        <h2>
                        <a href={"../Videoview/" + user.sno} title={user.vtitle}  >
                            {user.vtitle}
                            </a>
                        </h2>
                        <div className="post-meta-wrap">
                                                        <div className="author vcard">
                                                            <i className="fa fa-user-circle" aria-hidden="true"></i>
                                                            <a href="" title={user.vmade}>{user.vmade}</a>
                                                            </div>
                                                            </div>
                        <div className="ytd-video-meta-block">
                            <span className="ytd-video-meta-block">{user.vview} views</span>
                            <span className="ytd-video-meta-block">2 years ago</span>
                        </div>
                    </div>
                </div>


                ))}

          
        </div>
    )
}

export default Videoviewlist
