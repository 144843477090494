import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { setUserSession } from './Utils/Common'
import './Login.css'
import Header from './Header'
import Leftmenu from './Leftmenu'
import Dashboard from './Dashboard'
import { getUser, removeUserSession } from './Utils/Common'

function Login () {
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const username = useFormInput('')
  const password = useFormInput('')
  const [error, setError] = useState(null)
  const [errore, setErrorE] = useState(null)
  const [errorp, setErrorP] = useState(null)

  const [notconn, setnotconn] = useState("")

  const [idp, setidp] = useState("")

  // handle button click of login form

  function refreshPage () {
    console.log("I am in refreshPage function")
    // window.reload(false)
  }
  const handleLogin = () => {
    var errorstatus = 0
    setError(null)
    setErrorP(null)
    setErrorE(null)
    if (username.value == '') {
      errorstatus = 1
      setErrorE('Please Enter Email ID!')
    }

    if (password.value == '') {
      errorstatus = 1
      setErrorP('Please Enter Password!')
    }

    if (errorstatus == 0) {
      setError(null)
      setLoading(true)
      axios
        .post('https://jabit.in/docstubeapi/users/adminlogin', {
          username: username.value,
          password: password.value
        })
        .then(response => {
          setLoading(false)
          setUserSession(
            response.data.user,
            response.data.user,
             response.data.name
          )
         
          //   window.$username=response.data.name

          if(response.data.user){
            history.push('./Dashboard')
          }
            
           window.location.reload(false);
            
        })
        .catch(error => {
          setLoading(false)
         
          // setnotconn("Please Check Your Internet Connection")
        //  window.location.reload(false);
          //  if (error.response.status === 401)
          //    setError(error.response.data.message)
          //  else if (error.response.status === 400)
          //    setError(error.response.data.message)
          //  else 

           setError('Something went wrong. Please try again later.' )
           setidp("OR May be Email and Password not correct")
        })
    }
  }

  const sescheck = getUser()

  //  if (sescheck) {
  //    return (
  //      <div>
  //        <Header />
  //        <Leftmenu />
  //      {/* <Dashboard /> */}
  //        {/* <NavLink onClick={handleLogout} to=''>
  //          <i class='fa fa-sign-out'></i>
  //          <span>Logout</span>
  //        </NavLink> */}
  //      </div>
  //    )
  //   }

  return (

    <div className="app-content content" style={{marginLeft: "0px"}}>
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row">
        </div>
        <div className="content-body"><section className="row flexbox-container">
  <div className="col-12 d-flex align-items-center justify-content-center">
    <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
      <div className="card border-grey border-lighten-3 m-0">
        <div className="card-header border-0">
          <div className="card-title text-center">
            <div className="p-1">
            <img src={window.$mainpath + 'images/logo/logo.png'} alt="logo" style={{maxWidth: '50%'}}/>
   
             </div>
          </div>
          <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span>Login with Docstube</span>
          </h6>
        </div>
        <div className="card-content">
          <div className="card-body">
            <form className="form-horizontal form-simple" action="index.html" novalidate>
              <fieldset className="form-group position-relative has-icon-left mb-0">
              <input
                        type='text'
                        {...username}
                        autoComplete='new-password'
                        autocorrect='off'
                        className='form-control'
                        autocapitalize='off'
                        placeholder='Your Username'
                        autofocus=''
                      />
                      {errore && (
                        <>
                          <small style={{ color: 'red' }}>{errore}</small>
                        </>
                      )}
              
                <div className="form-control-position">
                  <i className="la la-user"></i>
                </div>
              </fieldset>
              <fieldset className="form-group position-relative has-icon-left">
              <input
                        type='password'
                        {...password}
                        autoComplete='new-password'
                        className='form-control'
                        placeholder='Enter Password'
                      />
                       
                      {errorp && (
                        <>
                          <small style={{ color: 'red' }}>{errorp}</small>
                        </>
                      )}
                
                <div className="form-control-position">
                  <i className="la la-key"></i>
                </div>
              </fieldset>
              <input
                      href=''
                      className='btn btn-info btn-block'
                      type='button'
                      
                      value={loading ? 'Loading...' : 'Login'}
                      onClick={handleLogin}
                      disabled={loading}
                    /> <br/>
                    {error && (
                      <>
                        <small style={{ color: 'red' }}>{error} </small><br/>{idp}
                      </>
                    )}
            
            </form>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</section>

        </div>
      </div>
    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)

  const handleChange = e => {
    setValue(e.target.value)
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login
