import React from 'react'
import { useState, useEffect } from "react";
//  import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { getUser } from './Utils/Common';
import axios from 'axios';
import $ from 'jquery'
import Doctorlist from './Doctorlist'

function Editdoctor(props) {

    console.log(props)
    console.log("afterallprops")
    console.log(props.match.params.id)
    const sescheck = getUser()
    //alert(sescheck.userId)
    var a = sescheck.userId;

//     const [state, setState] = useState({

//         // firstName: "",
//         // lastName: "",
//         // contact: "",
//         // address: "",
//         // city : "",
//         // states:"",
//         // degree:"",
//         // areaofexpertise:"",
//         // previouscompany:"",
//         // previoustitle:"",
//         // trainerby:"",
//         // internship:"",
//         // email:"",

//         // profile: props.match.params.id,


//         firstname: "",
//         lastName: "",
//         contact: "",
//         address: "",
//         city : "",
//         states:"",
//         degree:"",
//         areaofexpertise:"",
//         previouscompany:"",
//         previoustitle:"",
//         trainerby:"",
//         internship:"",
//         email:"",

//         profile: props.match.params.id
//       })

//       const [items, setItems] = useState([]);

//       const  handleChange=(e)=> {
//         const value = e.target.value;
//         setState({
//           ...state,
//           [e.target.name]: value,
          
//           profile: props.match.params.id
//         });
//       }

//       const [users, setUsers] = useState([]);
//       useEffect(() => {
//         getUsers();
//     }, []);


    
//     async function getUsers() {
//         const response = await fetch(window.$urlpath + 'users/userprofiledata?vid=' + props.match.params.id);
//         const users = await response.json();
//         setUsers(users);



//     }
      
//   const resetForm=(e)=>{
//     console.log("I am in resetForm Handler")
//     const value = "";
//      setState({
//       firstName: "",
//       lastName: "",
//       contact: "",
//       address: "",
//       city : "",
//       states:"",
//       degree:"",
//       areaofexpertise:"",
//       previouscompany:"",
//       previoustitle:"",
//       trainerby:"",
//       internship:"",
//       email:"",

//       profile: ""

//      });
    
//   }


//       const changeSubmit=(e)=>{
//           console.log(state);
//           e.preventDefault();

//     //   connecting and passing data  

//     //  const { firstname, lastname, contact, address, city, states, degree, areaofexpertise, previouscompany, previoustitle,
//     //     trainerby,  internship, email, profile  } = state;

//         const { firstname, lastname, contact, address, city, states, degree, areaofexpertise, previouscompany, previoustitle,
//             trainerby,  internship, email, profile  } = state;
//         //console.log('state=>,',state)


//     //   fetch('http://localhost:9000/register' , {
//     //     method: "POST",
//     //     headers: {
//     //    'Content-type': 'application/json',
//     //    'Accept': 'application/json',
//     //    },
//     //     body: JSON.stringify(state)
//     //   })

//     //    .then((result) => result.json())
//     //    .then((items) => {
//     //     console.log('api',items); 
//     //     setItems(items)
          
//     //   })

//         axios.post(window.$urlpath + 'user/updateadminprofile', { body: JSON.stringify(state) })
//         .then((result) => result.json())
//         .then((items) => {
//          console.log('api',items); 
//          setItems(items)
          
//        })

//       }
  
//     return (


//         <React.Fragment>
//              {
//                 users.map(user => (
//          <div className="app-content content">
             
      
//       <div className="content-wrapper">
        
//         <div className="content-body">
//         <section id="add-doctors">
//          <div className="icon-tabs">
//         <div className="row">
//             <div className="col-12">
//                 <div className="card">
//                     <div className="card-header">
//                         <h4 classNames="card-title">Edit Doctors</h4>
//                         <a href="#" className="heading-elements-toggle"><i className="la la-ellipsis-h font-medium-3"></i></a>
//                     </div>
//                     <div className="card-content collapse show">
//                         <div className="card-body">

//                 {/* {items.map((item, index) => (

//                  <div id="Table" className=" container row">
                     
//                   <table  className="row table table-striped table-primary  float-right"> */}
//                     {/* <thead >
//                       <tr>
//                         <h4><th>firstName</th></h4>
//                         <h4><th>lastName</th></h4>
//                         <h4><th>contact</th></h4>
//                         <h4><th>address</th></h4>
//                         <h4><th>states</th></h4>
//                         <h4><th>degree</th></h4>
//                         <h4><th>areaofexpertise</th></h4>
//                         <h4><th>previouscompany</th></h4>
//                         <h4><th>trainerby</th></h4>
//                         <h4><th>internship</th></h4>
//                         <h4><th>email</th></h4>
//                       </tr>
//                       </thead> */}
//                      {/* <tbody> */}
//                     {/* <tr> */}
//                        {/* <td> <h5 key={index}>{item.firstName}</h5></td>
//                        <td> <h5 key={index}>{item.lastName}</h5></td>
//                        <td> <h5 key={index}>{item.contact}</h5></td>
//                        <td> <h5 key={index}>{item.address}</h5></td>
//                        <td> <h5 key={index}>{item.states}</h5></td>
//                        <td> <h5 key={index}>{item.degree}</h5></td>
//                        <td> <h5 key={index}>{item.areaofexpertise}</h5></td>
//                        <td> <h5 key={index}>{item.previouscompany}</h5></td>
//                        <td> <h5 key={index}>{item.trainerby}</h5></td>
//                        <td> <h5 key={index}>{item.internship}</h5></td>
//                        <td> <h5 key={index}>{item.email}</h5></td>
//                       */}
//                      {/* </tr>
//                     </tbody>
                     

//                   </table>
//                   </div>
//               ))} */}

                
              
//             <form onSubmit={changeSubmit} className="add-doctors-tabs icons-tab-steps steps-validation wizard-notification">
//             <h4 className="form-section"><i className="la la-user"></i> Personal Info</h4> {user.user_fname} 
//             <div className="row">
//              <div className="col-md-4">
//                <div className="form-group">
//                  <label for="firstName">First Name:<span className="danger">*</span></label>
//                    <input type="text" name="firstName" className="form-control required"  required value={state.firstName} onChange={handleChange}/>
//              </div>
//                </div>

//               <div className="col-md-4">
//                 <div className="form-group">
//                  <label for="firstName">Last name:<span className="danger">*</span></label>
//                    <input type="text" name="lastName" className="form-control required" required value={state.lastName} onChange={handleChange} />
//                 </div>
//                   </div>

//               <div className="col-md-4">
//                 <div className="form-group">
//                   <label for="firstName">Contact Number:<span className="danger">*</span></label>
//                     <input type="tel" name="contact" className="form-control required"   value={state.contact}  placeholder="Enter phone number" onChange={handleChange} />
//                 </div>
//                   </div>
//               </div>


//               <div className="row">
//              <div className="col-md-4">
//                <div className="form-group">
//                  <label for="firstName">Address:<span className="danger">*</span></label>
//                   <input type="text" className="form-control required" name="address"   value={state.address} onChange={handleChange} />
//              </div>
//                </div>

//               <div className="col-md-4">
//                 <div className="form-group">
//                  <label for="firstName">City:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="city"   value={state.city} onChange={handleChange} />
//                 </div>
//                   </div>

//               <div className="col-md-4">
//                 <div className="form-group">
//                   <label for="firstName">State:<span className="danger">*</span></label>
//                   <input type="text" className="form-control required" name="states"   value={state.states} onChange={handleChange} />
//                 </div>
//                   </div>
//               </div>

//               <h4 className="form-section"><i className="la la-mortar-board"></i> Education Info</h4>
//               <div className="row">
//              <div className="col-md-6">
//                <div className="form-group">
//                  <label for="firstName">Degree Held:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="degree"  value={state.degree} onChange={handleChange} />
//              </div>
//                </div>

//               <div className="col-md-6">
//                 <div className="form-group">
//                  <label for="firstName">Area of Expertise:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="areaofexpertise"   value={state.areaofexpertise} onChange={handleChange} />
//                 </div>
//                   </div>

              
//               </div>


//               <h4 className="form-section"><i className="la la-briefcase"></i> Previous Experience Info</h4> 
//               <div className="row">
//              <div className="col-md-6">
//                <div className="form-group">
//                  <label for="firstName">Previous Company:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="previouscompany"  value={state.previouscompany} onChange={handleChange} />
//              </div>
//                </div>

//               <div className="col-md-6">
//                 <div className="form-group">
//                  <label for="firstName">Previous Title:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="previoustitle"  value={state.previoustitle} onChange={handleChange} />
//                 </div>
//                   </div>

              
//               </div>



//               <div className="row">
//              <div className="col-md-6">
//                <div className="form-group">
//                  <label for="firstName">Trainer By:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="trainerby"  value={state.trainerby} onChange={handleChange} />
//              </div>
//                </div>

//               <div className="col-md-6">
//                 <div className="form-group">
//                  <label for="firstName">Internship:<span className="danger">*</span></label>
//                  <input type="text" className="form-control required" name="internship"  value={state.internship} onChange={handleChange} />
//                 </div>
//                   </div>

              
//               </div>
//               <h4 className="form-section"><i className="la la-share-alt"></i> Social Info</h4> 
//               <div className="row">
//              <div className="col-md-6">
//                <div className="form-group">
//                  <label for="firstName">Email:<span className="danger">*</span></label>
//                  <input type="email" className="form-control required" name="email" value={state.email} onChange={handleChange} />
//              </div>
//                </div>

            
              
//               </div>
              
//               <div className="form-actions text-right">
// 	                            <button type="button" onClick={resetForm} className="btn btn-warning mr-1">
// 	                            	<i className="ft-x"></i> Cancel
// 	                            </button>
// 	                            <button type="submit" value="Submit" className="btn btn-primary">
// 	                                <i cclassName="la la-check-square-o"></i> Update
// 	                            </button>
// 	                        </div>

              



//                {/* Date */}


//               </form>
             
//               </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </section>
//             </div>
//               </div>
              
//               </div>
//               ))}

//         </React.Fragment>
//     )

    
const [users, setUsers] = useState([]);
const [items, setItems] = useState([]);
const [employee, setEmployee] = useState({});

useEffect(() => {
    getUsers();
}, []);




function validateEmail(elementValue) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
}


const [loading, setLoading] = useState(false);
const userfname = useFormInput('');
const userlname = useFormInput('');
const userphone = useFormInput('');
const usergender = useFormInput('');
const userstatus = useFormInput('');
const userbio = useFormInput('');
const useradd = useFormInput('');
const useradd2 = useFormInput('');
const usercity = useFormInput('');
const userstate = useFormInput('');
const usercountry = useFormInput('');
const userpostcode = useFormInput('');

    async function getUsers() {
             const response = await fetch(window.$urlpath + 'users/userprofiledata?vid=' + props.match.params.id);
            const users = await response.json();
             setUsers(users);
    }

    const [error, setError] = useState(null);


// handle button click of login form
const handleLogin = () => {
    var errorstatus = 0;
    setError(null);
    setLoading(true);


    var userfname = $("#userfname").val();
    var userlname = $("#userlname").val();
    var userphone = $("#userphone").val();
    var usergender = $("#usergender").val();
    var userstatus = $("#userstatus").val();
    var userbio = $("#userbio").val();
    var useradd = $("#useradd").val();
    var useradd2 = $("#useradd2").val();
    var usercity = $("#usercity").val();
    var userstate = $("#userstate").val();
    var usercountry = $("#usercountry").val();
    var userpostcode = $("#userpostcode").val();

    axios.post(window.$urlpath + 'users/updateadminprofile', { userfname: userfname, userlname: userlname, userphone: userphone, usergender: usergender,userstatus:userstatus, userbio: userbio, useradd: useradd, useradd2: useradd2, usercity: usercity, userstate: userstate, usercountry: usercountry, userpostcode: userpostcode ,profile:props.match.params.id}).then(response => {
        setLoading(false);
       console.log(response.data.message)
            // setUserSession(response.data.token, response.data.user);
             //  props.history.push('/dashboard');
        setError("Profile data updated successfully!");
        setItems(response.data.message)
       }).catch(error => {
        setLoading(false);
        if (error.response.status === 401) setError(error.response.data.message);
        else if (error.response.status === 400) setError(error.response.data.message); 
        else setError("Something went wrong. Please try again later.");
    });
}
// {response.data.message}

return (
    <div className="app-content content">
             
      
     <div className="content-wrapper">
            
      <div className="content-body">
    <div class="hs_kd_sidebar_main_wrapper hs_num_dcotor_profile_wrapper">
        {
            users.map(user => (<div class="">
                <div class="">
                    {/* <Usermenu /> */}

                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <div className="card">

                            <div className="card-body">
   

                                <h4 className="card-title">Basic Information</h4>

                              


                                <div className="row form-row">


                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="change-avatar">
                                                <div className="upload-img">
                                                    <div className="change-photo-btn"><span><i className="fa fa-upload"></i> Upload Photo</span>
                                                        <input type="file" className="upload" />
                                                    </div>
                                                    <small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="col-md-6">
                                        {/* <div className="form-group">
                                            <label>Username <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" defaultValue={user.user_fname + " " + user.user_lname} />
                                        </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        {/* <div className="form-group">
                                            <label>Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" defaultValue={user.user_email}  />
                                        </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input type="text" id="userfname"  required className="form-control" defaultValue={user.user_fname} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input type="text" id="userlname"  required className="form-control" defaultValue={user.user_lname} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Phone Number</label>
                                            <input type="text" id="userphone"  required className="form-control" defaultValue={user.user_phone} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <select required className="form-control  select" id="usergender"  required>
                                                <option>Select</option>
                                                <option selected={user.user_gender === 0} value="0">Male </option>
                                                <option selected={user.user_gender === 1} value="1" >Female</option>  
                                            </select>
                                        </div>


                                        <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select required className="form-control  select" id="userstatus"  required>
                                                <option>Select</option>
                                                <option selected={user.user_status === 1} value="1">Active</option>
                                                <option selected={user.user_status === 0} value="0">Inactive</option>
                                            </select>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">About Me</h4>
                                <div className="form-group mb-0">
                                    <label>Biography</label>
                                    <textarea required className="form-control" id="userbio" rows="5" required>{user.user_bio}</textarea>
                                </div>
                            </div>
                        </div>




                        <div className="card contact-card">
                            <div className="card-body">
                                <h4 className="card-title">Contact Details</h4>
                                <div className="row form-row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address Line 1</label>
                                            <input type="text" id="useradd"  required className="form-control" defaultValue={user.user_address} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Address Line 2</label>
                                            <input type="text" id="useradd2" required className="form-control" defaultValue={user.user_address_new} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">City</label>
                                            <input type="text" id="usercity" required className="form-control" defaultValue={user.user_city} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">State / Province</label>
                                            <input type="text" id="userstate" required className="form-control" defaultValue={user.user_state} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Country</label>
                                            <input type="text" id="usercountry" required className="form-control" defaultValue={user.user_country} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Postal Code</label>
                                            <input type="text" id="userpostcode" required className="form-control" defaultValue={user.user_postcode} required/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="submit-section submit-btn-bottom">
                            <input type="button" value={loading ? 'Loading...' : 'Save Changes'} onClick={handleLogin} className="btn btn-primary submit-btn" />{items}
                        </div>



                    </div>
                   
                   <form/>

                </div>
            </div>
            ))}
    </div>
    </div>
    </div>
    </div>
)
}

const useFormInput = initialValue => {
const [value, setValue] = useState(initialValue);

const handleChange = e => {
    setValue(e.target.value)
    
}
return {
    value,
    onChange: handleChange
}
}

export default Editdoctor
