import React from 'react';
import { useState, useEffect } from "react";
import ShowMoreText from 'react-show-more-text';
import './Videoview.css'
import Videoviewlist from './Videoviewlist';
import { Route, useParams } from "react-router-dom";
import axios from 'axios';
import queryString from 'query-string';
//function Videoview() {
import { useHistory } from "react-router-dom";
import { NavLink } from 'react-router-dom';
const publicIp = require('public-ip');

const Videoview = (props) => {
    var a = "";
    //function Videoview(props) {
    const [users, setUsers] = useState([]);
    const [employee, setEmployee] = useState({});

    useEffect(() => {
        getUsers();
    }, []);



    const [vmade, setvmade] = useState([]);
    const [vfocus, setvfocus] = useState([]);
    const [vimage, setimage] = useState([]);
    const [vpractice, setvpractice] = useState([]);

    const [userip, setIp] = useState([]);
    const [useripsno, setIpSno] = useState([]);

    const [useripsnodata, setIpSnoData] = useState([]);
    const [madebyid, setMadeBy] = useState([]);

    async function getUsers() {

        const userip = await publicIp.v4();
        setIp(userip);

        const response = await fetch(window.$urlpath + 'users/videodetaildata?vid=' + props.match.params.id + '&ip=' + userip);
        const users = await response.json();
        setUsers(users);
        setvmade(users[0].vmade);
        setvfocus(users[0].vfocus);
        setimage(users[0].vimage);
        setvpractice(users[0].vpractice);
        setIpSno(users[0].vlikesno);
        setMadeBy(users[0].userid);

        // const responserelate = await fetch('http://localhost:4000/users/videodatarelate?vid=' + props.match.params.id);
        // const employee = await responserelate.json();
        // setEmployee(employee);



    }

    async function handlerLike() {
        axios.post(window.$urlpath + 'users/likevideo', { userip: userip, vid: props.match.params.id }).then(response => {
            const useripsno = response.data[0].id;
            setIpSno(response.data[0].id);
            //console.log(response.data[0].id)
        }).catch(error => {
        });
    }

    async function handlerDisLike() {
        const response = await fetch(window.$urlpath + 'users/dislikevideo?vid=' + useripsno);
        setIpSno(null);
    }

    return (
        <div className="app-content content">
        <div className="content-wrapper">
            <div className="content-body">
                <div className="hs_kd_sidebar_main_wrapper hs_num_video_view_wrapper">

                    <div className="row">

                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div class="player-size"></div>
                            {
                                users.map(user => (<video className="videotag" loop={true} autoPlay="true"
                                    controls disablePictureInPicture controlsList="nodownload">
                                    <source src={window.$urlvideo + 'docvideo/' + user.vurl} type="video/mp4" />
                                </video>
                                ))}

                            {
                                users.map(user => (<div key={user.sno} className="single-video-title">
                                    <h2><a href="">{user.vtitle}</a></h2>
                                    <div class="ytd-video-meta-block">
                                        <span class="ytd-video-meta-block"><i className="fa fa-eye"></i>{user.vview} views</span>
                                        <span class="ytd-video-meta-block"><i className="fa fa-calendar-o"></i> {user.vdate}</span>
                                    </div>
                                </div>

                                ))}
                            <div className="single-video-author box">
                                <div className="pull-right">
                                    <button className="btn-outline-danger" type="button" >
                                        {!useripsno && <i className="fa fa-heart-o" onClick={handlerLike} ></i>}
                                        {useripsno && <i className="fa fa-heart" onClick={handlerDisLike} ></i>}

                                        <span>Danke Arzt!</span>
                                    </button>
                                </div>
                                <img className="img-fluid" src={"https://jabit.in/docprofile/" + vimage} alt="" />
                                <p>

                                    <NavLink title={vmade} exact to={"/Doctorprofileview/" + madebyid} ><strong>{vmade}</strong></NavLink>
                                    <span><i className="fa fa-check-circle text-success"></i></span>
                                </p>
                                {/* <small>Rosenpark Praxis</small> */}
                                <small>{vpractice}</small>

                            </div>



                            <div className="single-video-des box">
                                <ShowMoreText
                                    lines={3}
                                    more='Show more'
                                    less='Show less'
                                    className=''
                                    anchorClass='my-anchor-css-class'

                                >
                                    {vfocus}
                                </ShowMoreText>
                            </div>
                            <div className="single-video-share box">
                                <span><a href="#"><i className="fa fa-facebook"></i></a></span>
                                <span><a href="#"><i className="fa fa-twitter"></i></a></span>
                                <span><a href="#"><i className="fa fa-instagram"></i></a></span>
                                <span><a href="#"><i className="fa fa-linkedin"></i></a></span>

                            </div>
                            {
                                users.map(user => (<div key={user.sno} className="single-video-info-content">
                                    {/* <h6>Cast:</h6>
                            <p>{user.vmade}</p>
                            <h6>Category :</h6>
                            <p>{user.vdep}</p>
                            <h6>About :</h6>
                            <p>{user.vdesc}</p> */}
                                    <h6>Tags :</h6>
                                    <p className="tags mb-0">{user.vtag}
                                        {/* <span><a href="#">Uncharted 4</a></span>
                                <span><a href="#">Playstation 4</a></span>
                                <span><a href="#">Gameplay</a></span>
                                <span><a href="#">1080P</a></span>
                                <span><a href="#">ps4Share</a></span>
                                <span><a href="#">+ 6</a></span> */}
                                    </p>
                                </div>
                                ))}                    


                       </div>


                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="row">
                                <Videoviewlist results={props.match.params.id} />
                            </div>
                        </div>





                    </div>
                </div>
            </div>

        </div>
    </div>

    )
}

export default Videoview
