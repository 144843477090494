import React from 'react'
import {NavLink, Link} from 'react-router-dom';

const Leftmenu = () => {

    return (
        <div className="main-menu menu-fixed menu-light menu-accordion menu-shadow ">
      <div className="main-menu-content">
        <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
          <li className="active">

          
          <Link exact to="/Dashboard"><i className="la la-home"></i><span className="menu-title" data-i18n="Dashboard">Dashboard</span></Link>
          </li>
          <li className=" navigation-header"><span data-i18n="Professional">Professional</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Professional"></i>
          </li>
          
          <li className="nav-item">
          <Link exact to="/AddDoctors" className="menu-button2"><i className="la la-stethoscope"></i><span className="menu-title">Add Doctors</span></Link>
          </li>

          <li className="nav-item">
          <Link exact to="/Doctorlist" className="menu-button2"><i className="la la-list"></i><span className="menu-title">Doctors List</span></Link>
              </li>
              <li className="nav-item">
          <Link exact to="/Videolist" className="menu-button2"><i className="la la-video"></i><span className="menu-title">Video List</span></Link>
              </li>
          
        </ul>
      </div>
    </div>
    )
}

export default Leftmenu
