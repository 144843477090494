import React from 'react'
import { useState, useEffect } from "react";
//  import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { getUser } from './Utils/Common';
import axios from 'axios';

   function AddDoctors(props) {

    console.log("I want to see props in console."+props)

    const sescheck = getUser()
    //alert(sescheck.userId)
    var a = sescheck.userId;
    const [state, setState] = useState({

        firstname: "",
        // lastname: "",
        contact: "",

        // address: "",
        // city : "",
        // states:"",
        // degree:"",
        // areaofexpertise:"",
        // previouscompany:"",
        // previoustitle:"",
        // trainerby:"",
        // internship:"",
        email:"",
        password:"",
        userstatus:""

      })

      const [items, setItems] = useState([]);
      const [message, setmessage] = useState("");

      const  handleChange=(e)=> {
        const value = e.target.value;
        setState({
          ...state,
          [e.target.name]: value
        });
      }
    
  const resetForm=(e)=>{
    console.log("I am in resetForm Handler")
    const value = "";
     setState({
      firstname: "",
      //  lastname: "",
       contact: "",
      // address: "",
      // city : "",
      // states:"",
      // degree:"",
      // areaofexpertise:"",
      // previouscompany:"",
      // previoustitle:"",
      // trainerby:"",
      // internship:"",
      
      email:"",
      password:"",
      userstatus:""
     });
    
  }


      const changeSubmit=(e)=>{
          console.log(state);
          e.preventDefault();

    //   connecting and passing data  

    //  const { firstname, lastname, contact, address, city, states, degree, areaofexpertise, previouscompany, previoustitle,
    //     trainerby,  internship, email } = state;

        const { firstname,  contact, email,password} = state;
        //console.log('state=>,',state)
//       fetch('http://localhost:9000/register' , {
//         method: "POST",
//         headers: {
//        'Content-type': 'application/json',
//        'Accept': 'application/json',
//        },
//         body: JSON.stringify(state)


//  })



      //  axios.post(window.$urlpath + 'users/doctoradd', { body: JSON.stringify(state) })


       fetch( window.$urlpath +'users/doctoradd' , {
                 method: "POST",
                headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                },
                 body: JSON.stringify(state)
                })
        
                .then((result) => result.json())
                .then((items) => {
                  console.log('api',items); 
                  setItems(items)  
                  setmessage(items.message)
                  console.log(items.message)
          
                  })
              }


          //     const [users, setUsers] = useState([]);

          //         useEffect(() => {
          //             getUsers();
          //         }, []);

          //         async function getUsers() {
          //           const response = await fetch(window.$urlpath + 'users/userprofiledata?vid=' + props.match.params.id);
          //          const users = await response.json();
          //           setUsers(users);
          //  }

  
    return (
        <React.Fragment>
         
              <div className="app-content content">
              
      
      <div className="content-wrapper">
        
        <div className="content-body">
        <section id="add-doctors">
    <div className="icon-tabs">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h4 classNames="card-title">Add Doctors</h4>
                        <a href="#" className="heading-elements-toggle"><i className="la la-ellipsis-h font-medium-3"></i></a>
                    </div>
                    <div className="card-content collapse show">
                

            {/* { users.map(user => ( */}
                      <div className="card-body">

                {/* {items.map((item, index) => (

                 <div id="Table" className=" container row">
                     
                  <table  className="row table table-striped table-primary  float-right"> */}
                    {/* <thead >
                      <tr>
                        <h4><th>firstName</th></h4>
                        <h4><th>lastName</th></h4>
                        <h4><th>contact</th></h4>
                        <h4><th>address</th></h4>
                        <h4><th>states</th></h4>
                        <h4><th>degree</th></h4>
                        <h4><th>areaofexpertise</th></h4>
                        <h4><th>previouscompany</th></h4>
                        <h4><th>trainerby</th></h4>
                        <h4><th>internship</th></h4>
                        <h4><th>email</th></h4>
                      </tr>
                      </thead> */}
                     {/* <tbody>
                    <tr>
                       <td> <h5 key={index}>{item.firstName}</h5></td>
                       <td> <h5 key={index}>{item.lastName}</h5></td>
                       <td> <h5 key={index}>{item.contact}</h5></td>
                       <td> <h5 key={index}>{item.address}</h5></td>
                       <td> <h5 key={index}>{item.states}</h5></td>
                       <td> <h5 key={index}>{item.degree}</h5></td>
                       <td> <h5 key={index}>{item.areaofexpertise}</h5></td>
                       <td> <h5 key={index}>{item.previouscompany}</h5></td>
                       <td> <h5 key={index}>{item.trainerby}</h5></td>
                       <td> <h5 key={index}>{item.internship}</h5></td>
                       <td> <h5 key={index}>{item.email}</h5></td>
                     
                     </tr>
                    </tbody>
                     

                  </table>
                  </div>
                    
              ))} */}
              
            <form onSubmit={changeSubmit} className="add-doctors-tabs icons-tab-steps steps-validation wizard-notification">
            
            <div className="row">
             <div className="col-md-6">
               <div className="form-group">
                 <label for="firstName">Name:<span className="danger">*</span></label>
                   <input type="text" name="firstname" className="form-control required" required value={state.firstname} onChange={handleChange}/>
             </div>
               </div>

               {/* <div className="col-md-4">
                <div className="form-group">
                 <label for="firstName">Last name:<span className="danger">*</span></label>
                   <input type="text" name="lastname" className="form-control required" required value={state.lastname} onChange={handleChange} />
                </div>
                  </div>  */}

              <div className="col-md-6">
                 <div className="form-group">
                  <label for="firstName">Contact Number:<span className="danger">*</span></label>
                    <input type="tel" name="contact" className="form-control required"  required value={state.contact}  placeholder="Enter phone number" onChange={handleChange} />
                </div> 
                  </div>
              </div>

         {/* 
              <div className="row">
             <div className="col-md-4">
               <div className="form-group">
                 <label for="firstName">Address:<span className="danger">*</span></label>
                  <input type="text" className="form-control required" name="address"  required value={state.address} onChange={handleChange} />
             </div>
               </div>

              <div className="col-md-4">
                <div className="form-group">
                 <label for="firstName">City:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="city"  required value={state.city} onChange={handleChange} />
                </div>
                  </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label for="firstName">State:<span className="danger">*</span></label>
                  <input type="text" className="form-control required" name="states"  required value={state.states} onChange={handleChange} />
                </div>
                  </div>
              </div> */}

              {/* <h4 className="form-section"><i className="la la-mortar-board"></i> Education Info</h4>
              <div className="row">
             <div className="col-md-6">
               <div className="form-group">
                 <label for="firstName">Degree Held:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="degree" required value={state.degree} onChange={handleChange} />
             </div>
               </div> */}

              {/* <div className="col-md-6">
                <div className="form-group">
                 <label for="firstName">Area of Expertise:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="areaofexpertise"  required value={state.areaofexpertise} onChange={handleChange} />
                </div>
                  </div> */}

              
           {/* </div> */}


              {/* <h4 className="form-section"><i className="la la-briefcase"></i> Previous Experience Info</h4> 
              <div className="row">
             <div className="col-md-6">
               <div className="form-group">
                 <label for="firstName">Previous Company:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="previouscompany" required value={state.previouscompany} onChange={handleChange} />
             </div>
               </div>

              <div className="col-md-6">
                <div className="form-group">
                 <label for="firstName">Previous Title:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="previoustitle" required value={state.previoustitle} onChange={handleChange} />
                </div>
                  </div>

              
              </div> */}


{/* 
              <div className="row">
             <div className="col-md-6">
               <div className="form-group">
                 <label for="firstName">Trainer By:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="trainerby"  value={state.trainerby} onChange={handleChange} />
             </div>
               </div>  

              <div className="col-md-6">
                <div className="form-group">
                 <label for="firstName">Internship:<span className="danger">*</span></label>
                 <input type="text" className="form-control required" name="internship"  value={state.internship} onChange={handleChange} />
                </div>
                  </div>
                                
                                
              </div>   selected={user.user_status === 0} 
                       selected={user.user_status === 1} 

              {/* <h4 className="form-section"><i className="la la-share-alt"></i> Social Info</h4>  */}
              <div className="row">
             <div className="col-md-6">
               <div className="form-group">
                 <label for="firstName">Email:<span className="danger">*</span></label>
                 <input type="email" className="form-control required" name="email" value={state.email} onChange={handleChange} />
             </div>
               </div>
   
              
              <div className="col-md-6">
               <div className="form-group">
                 <label for="firstName">Password:<span className="danger">*</span></label>
                 <input type="password" className="form-control required" name="password" value={state.password} onChange={handleChange} />
             </div>
               </div>
   
              </div>

                                 {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select required className="form-control  select" id="userstatus"  name="userstatus" required>
                                                <option>Select</option>
                                                <option  value="1" onchange={handleChange}>Active</option>
                                                <option  value="0" onchange={handleChange}>Inactive</option> 
                                            </select>
                                        </div>
                                      </div>    */}
              
              <div className="form-actions text-right">
	                            <button type="button" onClick={resetForm} className="btn btn-warning mr-1">
	                            	<i className="ft-x"></i> Cancel
	                            </button>
	                            <button type="submit" value="Submit" className="btn btn-primary">
	                                <i cclassName="la la-check-square-o"></i> Save
	                            </button> {message}
	                        </div>

              </form>
             
              </div>

              {/*  ))} */}

                    </div>

                </div>
            </div>
        </div>
    </div>
  </section>
            </div>
              </div>
             
              </div>
              

        </React.Fragment>
    )
}

export default AddDoctors
