import React from 'react'
import { useHistory } from "react-router-dom";
import { getUser } from './Utils/Common';
import Login from './Login'
function Dashboard() {

  // const sescheck = getUser()
  //alert(sescheck.userId)
  // var a = sescheck.userId;
 let history = useHistory();


//  if (sescheck) {
    return (
        <div className="app-content content">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        
        <div className="content-body">
<div className="row">


  <div className="col-xl-3 col-lg-6 col-md-6 col-12">
    <div className="card pull-up">
      <div className="card-content">
        <div className="card-body">
          <div className="media d-flex">
            <div className="align-self-center">
              <i className="la la-user-md font-large-2 success"></i>
            </div>
            <div className="media-body text-right">
              <h5 className="text-muted text-bold-500">Doctors Available</h5>
              <h3 className="text-bold-600">122</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  
  
   </div>


        </div>
      </div>
    </div>
    )


// }

// return(
// <Login/>

// )
  


}

export default Dashboard
