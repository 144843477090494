import React from 'react'
import { useState, useEffect } from "react";
//  import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { getUser } from './Utils/Common';
import axios from 'axios';

import $ from 'jquery'


function Viewprofile(props) {

    
    console.log(props)
    console.log("afterallprops")
    console.log(props.match.params.id)
    const sescheck = getUser()
    //alert(sescheck.userId)
    var a = sescheck.userId;



    
const [users, setUsers] = useState([]);
const [employee, setEmployee] = useState({});

useEffect(() => {
    getUsers();
}, []);




function validateEmail(elementValue) {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue);
}


const [loading, setLoading] = useState(false);
const userfname = useFormInput('');
const userlname = useFormInput('');
const userphone = useFormInput('');
const usergender = useFormInput('');
const userbio = useFormInput('');
const useradd = useFormInput('');
const useradd2 = useFormInput('');
const usercity = useFormInput('');
const userstate = useFormInput('');
const usercountry = useFormInput('');
const userpostcode = useFormInput('');

    async function getUsers() {
             const response = await fetch(window.$urlpath + 'users/userprofiledata?vid=' + props.match.params.id);
            const users = await response.json();
             setUsers(users);
    }

const [error, setError] = useState(null);




// handle button click of login form
const handleLogin = () => {
    var errorstatus = 0;
    setError(null);
    setLoading(true);


    var userfname = $("#userfname").val();
    var userlname = $("#userlname").val();
    var userphone = $("#userphone").val();
    var usergender = $("#usergender").val();
    var userbio = $("#userbio").val();
    var useradd = $("#useradd").val();
    var useradd2 = $("#useradd2").val();
    var usercity = $("#usercity").val();
    var userstate = $("#userstate").val();
    var usercountry = $("#usercountry").val();
    var userpostcode = $("#userpostcode").val();

    axios.post(window.$urlpath + 'user/updateadminprofile', { userfname: userfname, userlname: userlname, userphone: userphone, usergender: usergender, userbio: userbio, useradd: useradd, useradd2: useradd2, usercity: usercity, userstate: userstate, usercountry: usercountry, userpostcode: userpostcode }).then(response => {
        setLoading(false);
        //     // setUserSession(response.data.token, response.data.user);
        //     //  props.history.push('/dashboard');
        setError("Profile data updated successfully!");
    }).catch(error => {
        setLoading(false);
        if (error.response.status === 401) setError(error.response.data.message);
        else if (error.response.status === 400) setError(error.response.data.message);
        else setError("Something went wrong. Please try again later.");
    });
}


return (
    <div class="hs_kd_sidebar_main_wrapper hs_num_dcotor_profile_wrapper">
        {
            users.map(user => (<div class="container">
                <div class="row">
                <div className="booking-doc-img">
                                {/* <img src={window.$urlvideo + "docprofile/" + user.user_image} className="img-fluid" alt={user.user_fname + " " + user.user_lname} /> */}
                                <img src={window.$urlimage+ user.user_image} alt="" className="card-img-top img-fluid rounded-circle w-25 mx-auto mt-1"/>
                            </div>
                    {/* <Usermenu /> */}

                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Basic Information</h4>

                              


                                <div className="row form-row">
                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="change-avatar">
                                                <div className="upload-img">
                                                    <div className="change-photo-btn"><span><i className="fa fa-upload"></i> Upload Photo</span>
                                                        <input type="file" className="upload" />
                                                    </div>
                                                    <small className="form-text text-muted">Allowed JPG, GIF or PNG. Max size of 2MB</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        {/* <div className="form-group">
                                            <label>Username <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" value={user.user_fname + " " + user.user_lname} disabled/>
                                        </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        {/* <div className="form-group">
                                            <label>Email <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" defaultValue={user.user_email} disabled />
                                        </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input type="text" id="userfname" className="form-control" defaultValue={user.user_fname} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input type="text" id="userlname" className="form-control" defaultValue={user.user_lname} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Phone Number</label>
                                            <input type="text" id="userphone" className="form-control" defaultValue={user.user_phone} disabled/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <input type="text" id="userphone" className="form-control" defaultValue={user.user_gender}disabled/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>User Status</label>
                                            <input type="text" id="userphone" className="form-control" defaultValue={user.user_status} disabled/>
                                        </div>
                                    </div>

                                   
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <select className="form-control  select" id="usergender"  >
                                                <option>Select</option>
                                                <option selected={user.user_gender === "0"} value="0">Male</option>
                                                <option selected={user.user_gender === "1"} value="1" >Female</option>
                                            </select>
                                        </div>
                                    </div> */}


                               
                                    
                                </div>
                            </div>
                        </div>





                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">About Me</h4>
                                <div className="form-group mb-0">
                                    <label>Biography</label>
                                    <textarea className="form-control" id="userbio" disabled rows="5">{user.user_bio}</textarea>
                                </div>
                            </div>
                        </div>




                        <div className="card contact-card">
                            <div className="card-body">
                                <h4 className="card-title">Contact Details</h4>
                                <div className="row form-row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address Line 1</label>
                                            <input type="text" id="useradd" className="form-control" defaultValue={user.user_address} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Address Line 2</label>
                                            <input type="text" id="useradd2" className="form-control" defaultValue={user.user_address_new} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">City</label>
                                            <input type="text" id="usercity" className="form-control" defaultValue={user.user_city} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">State / Province</label>
                                            <input type="text" id="userstate" className="form-control" defaultValue={user.user_state} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Country</label>
                                            <input type="text" id="usercountry" className="form-control" defaultValue={user.user_country} disabled/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Postal Code</label>
                                            <input type="text" id="userpostcode" className="form-control" defaultValue={user.user_postcode} disabled/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <div className="submit-section submit-btn-bottom">
                            <input type="button" value={loading ? 'Loading...' : 'Save Changes'} onClick={handleLogin} className="btn btn-primary submit-btn" />
                        </div> */}



                    </div>


                </div>
            </div>
            ))}
    </div>
)
}

const useFormInput = initialValue => {
const [value, setValue] = useState(initialValue);

const handleChange = e => {
    setValue(e.target.value);
}
return {
    value,
    onChange: handleChange
}
}



export default Viewprofile
